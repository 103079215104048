.bl-1 {
    border-left: 3px solid black;
}

.br-1 {
    border-right: 3px solid #CCC;
}

.btn-xs {
    padding: .25rem .5rem;
    font-size: .75rem;
    line-height: 1;
    border-radius: .2rem;
}

.blue-title {
    color: #004c84;
}

.pill {
    border: none;
    padding: .25rem .5rem;
    font-size: .75rem;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: .25rem .2rem;
    border-radius: 1rem;
}

.ProcessesHistoryContainer {
    height: calc(100vh - 68px);
    max-height: calc(100vh - 68px);
}

.box-grow-no-scroll {
    flex: 1;
    min-height: 0;
}