.preview-card {
    min-width: 320px;
    max-width: 320px;
    width: 320px;
    height: 210px;
    padding: 10px 10px;
    margin: 15px 25px;
    cursor: pointer;
}

.filling-empty-space-card {
    width: 320px; /*the width of the images in this example*/
    margin: 0px 25px;
    height: 0; /*Important! for the divs to collapse should they fall in a new row*/
}

.preview-card .card-title {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.15rem;
    text-align: center;
}

.preview-card .card-text {
    /* text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.preview-card .card-body {
    overflow: hidden;
    padding: 5px 0;
    font-size: 0.8rem;
}

.preview-card .card-img {
    width: 100%;
    height: 125px;
}

.preview-card .centered-img {
    object-fit: none;
    object-position: 50% 0%;
}

.preview-card .icon-img {
    width: 100%;
    height: 80px;
    margin-top: 20px;
    padding-left: 25%;
    padding-right: 25%;
}

.transparent-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.15);
    z-index: 2;
    display: none;
}

.preview-card:hover .transparent-overlay {
    display: block;
}

.preview-card .top-cb {
    z-index: 3;
    width: 1.10rem;
    height: 1.20rem;
}

.preview-card .cb-row{
    position: absolute;
}