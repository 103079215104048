td.fit {
    width: 1%;
    white-space: nowrap;
}

.catalog-master-container {
    height: calc(100vh - 68px);
    max-height: calc(100vh - 68px);
    display: flex;
    flex-direction: column;
}

.catalog-master-table {
    flex: 1 1 auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.catalog-master-table table {
    font-size: 0.7em;
    white-space: nowrap;
}

.userProcessTblTitle {
    color: #004c84;
}

.btn-blue {
    color: #004c84;
}

.pagination {
    margin-bottom: 0;
}