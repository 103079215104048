.filesSearchBar {
    border-style: solid;
    border-color: #D6D6D6;
    opacity: 1;
    border-width: 1px;
}

.filesSearchBarInput {
    width: 95%;
    color: #616262;
    outline: none;
    border-style: none;
    caret-color: #d6d6d6;
}

.filesSearchBarIcon {
    color: #004c84;
    width: 5%;
}

.filesNodesFrame {
    overflow-y: auto;
    -ms-overflow-y: auto;
}

.filesBackBtn {
    width: 4em;
}

.filesBtnsFrame {
    height: fit-content;
}

.filesActionsTitle {
    color: #004c84;
}

.filesComponentActionsBox {
    border-right: 1px solid #707070;
}

.filesComponentNodesFrame {
    max-height: 412px;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-y: auto;
}

.small-nav-breadcrumb .breadcrumb {
    margin-bottom: 0 !important;
    padding: 0.25em 0.5em;
    background-color: transparent;
}