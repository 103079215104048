.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
  }

.disconnected {
    background-color: #dc3545 !important;
}

.loading {
    background-color: #bbb;
}

.connected {
    background-color: #28a745 !important;
}

.success-checkmark::after {
    content: '✔';
    position: relative;
    left:25%; top: 25%;
    width: 25px; 
    height: 25px;
    text-align: center;
    border-radius: 50%;
    color: white;
  }

  .fail-mark::after {
    content: 'x';
    position: relative;
    left:25%; top: 25%;
    width: 25px; 
    height: 25px;
    text-align: center;
    border-radius: 50%;
    color: white;
    font-size: 1.5em;
    line-height: 20px;
  }

  .loading-mark::after {
    content: '\25CC';
    position: relative;
    left:25%; top: 25%;
    width: 25px; 
    height: 25px;
    text-align: center;
    border-radius: 50%;
    color: white;
    font-size: 1.5em;
    line-height: 20px;
  }

.services-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    width: 400px;
}

.service-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.service-item-text {
    justify-self: start;
}

.service-item-status {
    justify-self: end;
}