.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  
  .spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner-overlay {
    z-index: 10;
    position: absolute;
    display: block;
    min-height: 100%;
    min-width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: rgba(0,0,0,.5);
  }