.avatarTitle {
    color: #004c84;
    font-size: 1.2em;
    height: fit-content;
}

.avatarOrganization {
    color: #707070;
    font-size: 1.1em;
    height: fit-content;
}

.avatar {
    max-height: 125px;
    max-width: 125px;
}