
.fileContextMenuFrame {
    background: #FCFCFC 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    box-shadow: 0px 3px 11px #00000029;
    width: 170px;
}

.fileContextMenuItem:hover {
    background: #00000029;
    cursor: pointer;
}