.userProcessTag {
    color: #7E9AC1;
}

.userProcessGray {
    background-color: #F0F0F0;
}

.userProcessLight {
    background-color: #ffffff;
}

.userProcessTblTitle {
    color: #004c84;
}

.userProcessTblContent {
    color: #707070;
}