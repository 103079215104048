@font-face {
  font-family: 'Lato';
  src: url('Utils/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('Utils/fonts/Lato-Bold.ttf');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: Lato;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
