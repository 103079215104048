.overview-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
}

.gen-info-card {
    -webkit-box-shadow: 0px 0px 3px 1px #CCCCCC; 
    box-shadow: 0px 0px 3px 1px #CCCCCC;
    padding: 1rem;
    margin: 0.75rem;
}

.gen-info-card .gen-info-card-title {
    /* color: #004c84; */
    text-align: center;
    font-size: 0.8rem;
}

.gen-info-card .gen-info-card-content {
    font-size: 2rem;
    text-align: center;
}

.multiline-info-card {
    background-color: #F2F2F2;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
}

.m-info-card-cell {
    display: flex;
    justify-content: space-between;
    min-width: 15rem;
    margin: 0.1rem 1.5rem;
}

.m-info-card-cell .m-info-card-content {
    font-weight: 700;
}

.overview-viz {
    width: 100%;
    max-height: 250px;
    padding-right: 1rem;
    padding-left: 1rem;
}

.overview-viz canvas {
    max-height: 250px;
}