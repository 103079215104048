.createLayoutTitle {
    color: #707070;
}

.createLayoutBackBtn {
    width: 4em;
    height: 100%;
}

.createLayoutColumnsWrapper {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 500px;
}

.createLayoutPageTwoTitle {
    color: #004c84;
}