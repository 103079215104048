.catalogEditorModal .modal-content {
    box-shadow: 6px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
    background-color: #fcfcfc;
    color: #004c84;
}

.catalogEditorModal {
    width: 90% !important;
    max-width: 90% !important;
    height: 90%;
}

.catalogEditorModal .modal-content {
    width: 100%;
    height: 100%;
}

.catalogEditorModal .modal-body {
    height: 100%;
    flex: 1;
    min-height: 0;
}

@media only screen and (max-width: 768px) {

    .catalogEditorModal {
        width: 75%;
    }

}

.catalogEditorModal .catalog-form-group {
    min-width: 250px;
    width: calc(100% - 1rem);
}

.flex-gap-1 {
    gap: 1rem;
}

.catalog-editor-form {
    display: grid;
    grid-template-columns: 1fr  1fr;
}