.itemThingHorizontal:hover, .itemThingHorizontal:hover * {
    cursor: pointer;
}

.itemThingHorizontal .itemThingLabel {
    color: #616262;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: center;
}

.itemThingHorizontal.itemThingGray {
    filter: brightness(0) saturate(100%) invert(39%) sepia(0%) saturate(406%) hue-rotate(228deg) brightness(93%) contrast(88%);
}

.itemThingHorizontal.itemThingGrayHover:hover {
    filter: brightness(0) saturate(100%) invert(39%) sepia(0%) saturate(406%) hue-rotate(228deg) brightness(93%) contrast(88%);
    cursor: pointer;
}

.itemThingHorizontal.itemThingNoHover:hover {
    cursor: pointer;
}

.itemThingHorizontal.itemThingYellowHover:hover {
    filter: brightness(0) saturate(100%)  invert(74%) sepia(70%) saturate(806%) hue-rotate(358deg) brightness(103%) contrast(106%);
    cursor: pointer;
}

.itemThingHorizontal.itemThingYellowFocus:focus {
    filter: brightness(0) saturate(100%)  invert(74%) sepia(70%) saturate(806%) hue-rotate(358deg) brightness(103%) contrast(106%);
    cursor: pointer;
}