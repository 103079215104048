.instructionsBoxFrame {
    background-color: #F7F7F7;
    box-shadow: 0px 3px 11px #00000029;
    border-radius: 28px;
    opacity: 1;
    min-height: 250px;
    width: 250px;
}

.instructionsBoxTitle {
    color: #004c84;
}

.instructionsBoxContent {
    color: #707070;
}