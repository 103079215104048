.userSideBarFrame {
    position: absolute;
    left: 0;
    top: 0;
    min-height: 100%;
    background-color: #fcfcfc;
    min-width: 325px;
    z-index: 10;
    box-shadow: 6px 8px 8px #00000029;
    opacity: 1;
}

