.inputModal .modal-content {
    box-shadow: 6px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
    background-color: #fcfcfc;
    color: #004c84;
}

.inputModal {
    width: 75% !important;
    max-width: 75% !important;
}

.product-form .form {
    width: 60%;
}

.product-form .file {
    width: 40%;
}

@media only screen and (max-width: 768px) {

    .inputModal {
        width: 75%;
    }

}