.vizServerChangeModal .modal-content {
    box-shadow: 6px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
    background-color: #fcfcfc;
    color: #004c84;
}

@media only screen and (max-width: 768px) {

    .vizServerChangeModal {
        width: 75%;
    }

}