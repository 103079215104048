.filter-card {
    width: 12rem;
    max-height: 40vh;
    height: 40vh;
    font-size: 0.7rem;
    -webkit-box-shadow: 0px 0px 3px 1px #CCCCCC; 
    box-shadow: 0px 0px 3px 1px #CCCCCC;
}

.filter-card .filter-title {
    color: #004c84;
}

.filter-card .filter-card-actions {
    display: flex;
    justify-content: end;
    margin-top: 0.5rem;
}

.filter-card .popover-body {
    height: calc(100% - 41px);
    display: flex;
    flex-direction: column;
}

.box-grow {
    flex: 1 1 auto;
    overflow-y: auto;
}