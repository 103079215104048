.process-selection {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
}

.process-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: auto;
}

.process-selection-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: stretch;
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-height: calc(100vh - 220px);
    height: calc(100vh - 220px);
}

.process-selection-container .left-panel {
    display: flex;
    width: 25%;
    border-right: 3px solid #CCC;
    padding-right: 0.5rem;
}

.process-selection-container .right-panel {
    display: flex;
    width: 75%;
    padding-left: 1rem;
}

.parameters-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 1rem;
}

.parameters-form .form-group {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.parameters-form input {
    width: 260px;
}

.layout-tab {
    /* background-color: #d6d6d6; */
    background-color: #fff;
    /* border-left: white solid 2px;
    border-right: white solid 2px; */
    border-bottom: #000 solid 1px;
}

.layout-tab a {
    color: #000;
}

.layout-tab .nav-link.active {
    /* background-color: #5789ae; */
    background-color: #fff;
    color: #004c84;
    font-weight: bold;
    font-size: 1.1rem;
}

.process-info-table-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}

.process-info-table-container .table {
    width: 75%;
    min-width: 400px;
}

.process-info-table-container .table thead {
    color: #004c84;
}

.process-tab {
    background-color: #F0F0F0;
    /* border-left: white solid 2px;
    border-right: white solid 2px; */
    margin: 5px;
    border-radius: 10px;
}

.process-tab a {
    /* border-bottom: solid 1px #000000; */
    color: #004c84;
    font-weight: bold;
    text-align: center;
}

.process-tab .nav-link.active {
    background-color: #e6eef5;
    /* border-bottom: solid 2px #5789ae; */
    border-radius: 10px;
    /* color: #5789ae; */
    color: #004c84;
    font-weight: bold;
}