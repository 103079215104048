
.arrow-5 {
	/* more triangle */
	position: relative;
	height: 0px; width: 0px;
	border: 12px solid;
	border-color: 
		darkcyan
		darkcyan
		transparent
		transparent;
	transform: rotate(45deg); 
}

.arrow-5:before {
	content: '';
	position: absolute;
	top: 0px; right: 0px;
	display: block;
	height: 12px; width: 16px;
	background-color: darkcyan;
	transform: 
		rotate(-45deg) 
		translate(2px, 1px);
}

.arrow {
	position: relative;
	display: flex;
	align-items: center;
}

.arrow-body {
	height: 12px;
	width: 100px;
	background-color: #0077CC;
	border-right: none;
}

.arrow-head {
	height: 0px; width: 0px;
	border: 14px solid;
	border-color: 
		#0077CC
		#0077CC
		transparent
		transparent;
	transform: rotate(45deg);
	position: relative;
	left: -14px;
}

.stepper-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
  }
  .stepper-item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
  
	@media (max-width: 768px) {
	  font-size: 12px;
	}
  }
  
  .stepper-item::before {
	position: absolute;
	content: "";
	border-bottom: 5px solid #ccc;
	width: 100%;
	top: 20px;
	left: -50%;
	z-index: 2;
  }
  
  .stepper-item::after {
	position: absolute;
	content: "";
	border-bottom: 5px solid #ccc;
	width: 100%;
	top: 20px;
	left: 50%;
	z-index: 2;
  }
  
  .stepper-item .step-counter {
	position: relative;
	z-index: 5;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #ccc;
	margin-bottom: 6px;
  }
  
  .stepper-item.active {
	font-weight: bold;
  }
  
  .stepper-item.completed .step-counter {
	background-color: #4bb543;
	color: white;
  }

  .stepper-item.failed .step-counter {
	background-color: #dc3545!important;
	color: white;
  }

  .stepper-item.userInteraction .step-counter {
	background-color: #ffc107!important;
	color: #343a40!important;
  }
  
  .stepper-item.completed::after {
	position: absolute;
	content: "";
	border-bottom: 5px solid #4bb543;
	width: 100%;
	top: 20px;
	left: 50%;
	z-index: 3;
  }

  .stepper-item.failed::after, .stepper-item.failed::before {
	border-bottom: 5px solid #dc3545!important;
	z-index: 4;
  }

  .stepper-item.userInteraction::after, .stepper-item.userInteraction::before {
	border-bottom: 5px solid #ffc107!important;
	z-index: 4;
  }
  
  .stepper-item:first-child::before {
	content: none;
  }
  .stepper-item:last-child::after {
	content: none;
  }

  .stepper-item .step-name {
	font-size: 12px;
  }

  .task-info-parameters .parameter-table {
	  max-width: calc(33% - 30px);
	  margin: 15px;
  }

  .parameters-list {
	  overflow-y: auto;
	  max-height: 280px;
  }