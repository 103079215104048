.storage-info-text-top {
    font-size: 0.8em;
    
}

.storage-info-text-bottom {
    font-size: 0.9em;
}

.storage-info-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}