.backButton {
    background-color: #004c84;
    border-color: #004c84;
    color: #ffffff;
    font-family: Lato;
}

.backButton:hover {
    background-color: #616262;
    border-color: #616262;
    cursor: pointer;
}