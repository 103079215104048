.resourceSelectorResource:focus {
    background-color: #d6d6d6;
    border-radius: 3px;
}

.resourceSelectorResourceSelected {
    background-color: #d6d6d6;
    border-radius: 3px;
}

.resourceSelectorResource:hover {
    cursor: pointer;
    background-color: #d6d6d6;
    border-radius: 3px;
}

.resourceSelectorResourceIcon {
    width: 1em;
}