.fileProgressSideBarFrame {
    position: absolute;
    right: 0;
    top: 0;
    min-height: 100%;
    height: 100vh;
    background-color: #fcfcfc;
    min-width: 350px;
    z-index: 10;
    box-shadow: -5px 5px 8px #00000029;
    opacity: 1;
    display: flex;
    flex-direction: column;
}

.fileProgressButton {
    position: absolute;
    right: 0;
    top: calc(45%);
    background-color: #004c84;
    width: 30px;
    height: 50px;
    z-index: 10;
    box-shadow: -2px 2px 4px #00000029;
    opacity: 1;
    border-radius: 5px 0px 0px 5px;
}

.fileProgressButton:hover {
    cursor: pointer;
    background-color: #707070;
}

.fileProgressTitle {
    color: #004c84;
}

.fileProgressSubtitle {
    color: #707070;
    font-size: 0.9em;
}

.fileProgressInfo {
    color: #707070;
    font-size: 0.8em;
}

.fileProgressState {
    color: #707070;
    letter-spacing: 0;
    font-size: 0.6em;
}

.progress{
    height: 0.3rem;
}

.progress-bar {
    background-color: #004c84;
    height: 0.3rem !important;
}

.fileInfoX {
    color: #707070;
    font-size: 0.8em;
    opacity: 0.7;
}

.fileInfoX:hover {
    cursor: pointer;
    opacity: 1;
}

.btn-xs {
    padding: .25rem .5rem;
    font-size: .75rem;
    line-height: 1;
    border-radius: .2rem;
}

.files-list {
    overflow-y: auto;
    flex-grow: 1;
}