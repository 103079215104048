.view-container {
    height: calc(100vh - 165px);
}

.view-container .fullscreen {
    height: 100%;
}

#vizContainer {
    /* height: calc(100vh - 200px); */
    height: 100%;
}

.blue-navigation {
    color: #004C84;
}

.form-control-sm.blue-navigation {
    border: #f8f9fa;
    background: #f8f9fa;
}

.btn-light.blue-navigation:hover:not(:disabled){
    color: #004C84;
}