.linkThingLabel {
    color: #707070;
    font-size: 1.05em;
    cursor: pointer;
    opacity: 0.8;
}

.linkThingLabel:hover {
    opacity: 1;
}

.linkThingIcon {
    width: 1.4em;
}