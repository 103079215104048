.landing-container {
    display: flex;
    height: 100vh;
}

.landing-container .left-container {
    width: 50%;
}

.landing-container .right-container {
    width: 50%;
}

/* The hero image */
.hero-image {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../Utils/img/g3background.png"); */

    height: 100%;
    width: 100%;

    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.background {
    height: 100%;
    width: 100%;
    background: #14374B;
    border: 1px solid #707070;
    position: relative;
}

.background .bg-logo {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 75%;
    height: 50%;
    filter: opacity(0.7)
}

.background-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.hero-text {
    text-align: left;
    position: absolute;
    bottom: 0;
    left: 0;
    /* transform: translate(-50%, -50%); */
    color: white;
    margin: 10px;
}

.right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right-container .logo-container {
    margin-bottom: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.right-container .actions-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right-container .sign-up-container {
    font-size: 0.9rem;
}

.login-btn {
    width: 100%;
    max-width: 320px;
    font-size: 0.75rem;
}

.login-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-container .form-group {
    width: 100%;
    margin-bottom: 1rem;
}