.app-main {
    height: 100vh;
}

.overflow-y-auto {
    overflow-y: auto;
}

.searchBar {
    border-style: solid;
    border-color: #D6D6D6;
    opacity: 1;
    border-width: 1px;
}

.searchBarInput {
    width: 100%;
    color: #616262;
    outline: none;
    border-style: none;
    caret-color: #d6d6d6;
}

.searchBarIcon {
    color: #004c84;
    width: 1.5rem;
}

.custom-control.custom-switch .custom-control-label::before {
    background-color: #adb5bd;
}

.custom-control.custom-switch .custom-control-label::after {
    background-color: #fff;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

.box-grow-no-scroll {
    flex: 1;
    min-height: 0;
}

.box-grow-scroll {
    flex: 1 1 auto;
    overflow: auto;
}

.blue-title {
    color: #004c84;
}