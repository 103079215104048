.newFolderModal {
    box-shadow: 6px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
    background-color: #fcfcfc;
    z-index: 10;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #004c84;
}

@media only screen and (max-width: 768px) {

    .newFolderModal {
        width: 75%;
    }

}