.dp-params-card {
    height: 350px;
}

.box-grow-scroll {
    flex: 1 1 auto;
    overflow: auto;
}

.ParametersCardsPanel {
    height: calc(100vh - 162px);
    max-height: calc(100vh - 162px);
}