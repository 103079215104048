.fileResourceSelectorTitle {
    color: #004c84;
}

.fileResourceSelectorBorder {
    border-right: 3px solid #004c84;
}

.fileResourceSelectorButton {
    min-width: 25%;
}