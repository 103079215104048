.mainframeDiv {
    min-height: 100%;
    width: 100%;
}

.mainframeTop {
    background-color: #004c84;
}

.mainframeMenuTop {
    background-color: #004c84;
    height: 200px;
}

.mainframeMenuBar {
    color: #ffffff;
    height: fit-content;
    cursor: pointer;
}

.mainFrameTitle {
    color: #ffffff;
}

.debug {
    border-style: solid;
}

@media screen and (max-width: 720px) {
    .mainFrameHide {
        visibility: hidden;
    }
}