.stepContent {
    font-size: 1.3em;
    background-color: #d6d6d6;
    color: #ffffff;
    flex-basis: 100%;
    text-align: center;
}

.stepContentActive {
    background-color: #5789ae;
}