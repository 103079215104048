.homeUserFrame {
    height: fit-content;
    top: 100px;
    z-index: 5;
}

.homeUserBox {
    min-height: 200px;
    width: fit-content;
    background-color: #ffffff;
    box-shadow: 0px 5px 6px #00000029;
    -webkit-box-shadow: 0px 5px 6px #00000029;
    border-radius: 30px;
    opacity: 1;
}

.homeTitle {
    color: #004c84;
    opacity: 1;
}

.homeSubtitle {
    color: #616262;
    opacity: 1;
}

.homeItemsFrame {
    top: 300px;
}