.closeButtonDiv {
    cursor: pointer;
    opacity: 0.3;
    color: #616262;
    font-size: 2em;
}

.closeButtonDiv:hover {
    opacity: 1;
}

.closeButtonDiv:after {
    display: inline-block;
    content: "\00d7";
}