.blueButton {
    background-color: #004c84;
    border-color: #004c84;
    color: #ffffff;
    font-family: Lato;
}

.blueButton:hover {
    background-color: #616262;
    border-color: #616262;
    cursor: pointer;
}

.blueButton:focus {
    background-color: #616262;
    border-color: #616262;
}

.blueButton:disabled {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    cursor: auto;
}