.iconWrapper {
    height: fit-content;
}

.iconWrapper:hover {
    cursor: pointer;
}

.paintWhiteMd {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7484%) hue-rotate(257deg) brightness(104%) contrast(100%);
    width: 7.5em;
}

.paintWhiteSm {
    width: 5em;
}

.blueMd {
    width: 18.5em;
}