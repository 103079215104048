.executionTab {
    font-size: 1.3em;
    background-color: #d6d6d6;
    color: #ffffff;
    font-weight: bold;
    /* border-left: white solid 2px;
    border-right: white solid 2px; */
}

.executionTab a {
    color: #ffffff;
}

.executionTab .nav-link.active {
    background-color: #5789ae;
}


.ExecutionTabsContainer {
    height: 100%;
    width: 100%;
    flex: 1 auto;
}