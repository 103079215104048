.processLogTitle {
    color: #004c84;
    font-family: Lato;
}

.processLogTag {
    color: #7E9AC1;
}

.processLogContent {
    color: #707070;
}

.processLogGray {
    background-color: #F0F0F0;
}
