.grayButton {
    background-color: #616262;
    border-color: #616262;
    color: #ffffff;
    font-family: Lato;
}

.grayButton:hover {
    background-color: #ffffff;
    color: #616262;
    border-color: #616262;
    cursor: pointer;
}

.grayButton:focus {
    background-color: #616262;
    border-color: #616262;
}

.grayButton:disabled {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    cursor: auto;
}