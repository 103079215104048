.processInstructionsTitle {
    color: #004c84;
}

.fileChooseStageText {
    color: #707070;
}

.fileChooseStageButton {
    min-width: 25%;
}

.layoutGoParamsFrame {
    max-height: 300px;
    overflow-y: auto;
    -ms-overflow-y: auto;
}