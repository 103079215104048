.checklist-card {
    width: 18rem;
    -webkit-box-shadow: 0px 0px 3px 1px #CCCCCC; 
box-shadow: 0px 0px 3px 1px #CCCCCC;
}

.checklist-card .checklist-title {
    color: #004c84;
}

.checklist-card .card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.box-grow {
    flex: 1 1 auto;
    overflow-y: auto;
}