.productOverviewModal .modal-content {
    box-shadow: 6px 6px 6px #00000029;
    border: 1px solid #707070;
    border-radius: 5px;
    opacity: 1;
    background-color: #fcfcfc;
    color: #004c84;
}

.productOverviewModal {
    /* width: 90% !important; */
    max-width: 90% !important;
    height: 90%;
}

.productOverviewModal .modal-content {
    /* width: 100%; */
    height: 100%;
}

.productOverviewModal .modal-body {
    height: 100%;
    flex: 1;
    min-height: 0;
}

@media only screen and (max-width: 768px) {

    .productOverviewModal {
        width: 75%;
    }

}

.productOverviewModal .product-info {
    min-width: 250px;
    margin: 1rem 1rem;
}

.productOverviewModal .product-statistics {
    min-width: 400px;
    margin: 1rem 1rem;
}

.productOverviewModal .product-charts-grid {
    min-width: 550px;
    margin: 1rem 1rem;
    gap: 0.75rem;
}

.productOverviewModal .product-charts-grid .product-chart {
    width: calc(275px - 0.75rem);
}

.productOverviewModal .product-info .card-img-top {
    padding: 0.5rem;
}